$(
	function() {
		app.dataLayer.init();
	}
)
window.dataLayer = window.dataLayer || [];

app.dataLayer = {
	_vars: {
		v: '1.0.1'
	},
	init: function() {

	},
	checkGtag: function() {
		if (!window.gtag) {
			app.utils.scripts.initWnd(
				function() {
					app.wnd.error(
						'Cannot find <b>window.gtag</b> object. Please visit <a target="_blank" href="https://support.google.com/google-ads/answer/12985952">https://support.google.com/google-ads/answer/12985952</a> to get more Info about it.',
						null
					)
				}
			)
		}
	},
	setCategoryForProduct: function(prodId, title) {
		prodId = app.url.getProdIdByUrl(prodId);
		var prodListTitles = app.getLData('prodsCategory', true);
		if (!prodListTitles) {
			prodListTitles = {};
		}
		prodListTitles[prodId] = title;
		app.setLData('prodsCategory', prodListTitles, true);
		return title;
	},
	getCategoryForProduct(prodId) {
		prodId = app.url.getProdIdByUrl(prodId);
		var prodListTitles = app.getLData('prodsCategory', true);
		if (prodListTitles && prodListTitles[prodId]) {
			return prodListTitles[prodId];
		}
		return '';
	},
	setListForProduct: function(prodId, title) {
		prodId = app.url.getProdIdByUrl(prodId);
		var prodListTitles = app.getLData('prodsList', true);
		if (!prodListTitles) {
			prodListTitles = {};
		}
		prodListTitles[prodId] = title;
		app.setLData('prodsList', prodListTitles, true);
		return title;
	},
	getListForProduct(prodId) {
		prodId = app.url.getProdIdByUrl(prodId);
		var prodListTitles = app.getLData('prodsList', true);
		if (prodListTitles && prodListTitles[prodId]) {
			return prodListTitles[prodId];
		}
		return '';
	}
};

app._vars.baseEventsArrV2 = app._vars.baseEventsArrV2 || [];

app._vars.baseEventsArrV2.push({
	fun: function(e) {
		var cartKey = e.data.id;
		if (!app._vars.dataLayerProdDelInfo) {
			return false;
		}
		var prodPos = app._vars.dataLayerProdDelInfo[cartKey];
		try {
			var prodInfo = dataLayerCart[0]['ecommerce']["add"]['products'][prodPos];
			window.dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {
						'products': [{
							'name': prodInfo['name'],
							'id': prodInfo['id'],
							'price': String(prodInfo['price']),
							'brand': prodInfo['brand'],
							'category': prodInfo['category'],
							'variant': prodInfo['variant'],
							'dimension1': prodInfo['dimension1'],
							'quantity': prodInfo['quantity']
						}]
					}}
			});
		} catch (e) {
			console.log(e);
		}
	},
	nm: 'udu-CART-ITEM-REMOVED'
})

app._vars.baseEventsArrV2.push({
	fun: function(e) {
		try {
			var prodInfo = window.dataLayer[0].ecommerce.detail.products[0];
		} catch(e) {
			return true;
		}

		var products = [
			{
				id: prodInfo.id,
				name: prodInfo.name,
				category: prodInfo.category,
				variant: prodInfo.variant,
				brand: prodInfo.brand,
				quantity: Number($('#pr_form_id_quantity').val())
			}
		];

		window.dataLayer.push({
			event: 'eec.add',
			ecommerce: {
				add: {
					actionField: {
						list: app.dataLayer.getListForProduct(app.prodInfo.id)
					},
					products: products
				}
			}
		});
		return true;
	},
	nm: 'udu_BEFORE_PROD_FORM_POSTED'
});
app._vars.baseEventsArrV2.push({
	fun: function(e) {
		var $el = e.data.$item;
		if ($el) {
			if ($el.closest('li.opl-sysnm-color').length === 0) {
				return false;
			}
			var $op = $el.find('option:selected');
			if ($op.length > 0) {
				try {
					window.dataLayer[0].ecommerce.detail.products[0].variant = $op.html();
				} catch(e) {

				}
			}
		}
	},
	nm: 'udu_PROD-OPTION-SELECT-CHANGED'
});